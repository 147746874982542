import { Row, Col, Space } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import PuprLogo from "../../assets/img/pupr.png";
import ImgIg from "../../assets/icon/instagram-white.png";
import ImgFb from "../../assets/icon/facebook-white.png";
import ImgYt from "../../assets/icon/youtube-white.png";
import ImgTwitter from "../../assets/icon/twitter-white.png";

import {
  FooterSection,
  Title,
  Large,
  Empty,
  AddressText,
  Image
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={24} xs={24}>
              <Image src={PuprLogo} alt="PUPR - Sanitasi" />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Title>Laman</Title>
              <Large to="/" left="true">
                Beranda
              </Large>
              <Large left="true" to="/about">
                Profil
              </Large>
              <Large left="true" to="/berita">
                Berita
              </Large>
              <Large left="true" to="/produk">
                Produk Hukum
              </Large>
              <Large left="true" to="/faq">
                FAQ
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/infografis">
                Infografis
              </Large>
              <Large left="true" to="/informasi-publik">
                Agenda
              </Large>
              <Large left="true" to="/galeri">
                Galeri
              </Large>
              <Large left="true" to="/kontak">
                Kontak
              </Large>
              <Large left="true" to="/sitemap">
                Sitemap
              </Large>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Row gutter={12} align="middle" className="m-t-1">
                <Col xs={24}>
                  <Title>Alamat</Title>
                  <AddressText>Gedung Cipta Karya Lt.7</AddressText>
                  <AddressText>Jl. Patimura no 20 Kebayoran Baru, Jakarta Selatan</AddressText>
                </Col>
                <Col xs={24}>
                  <Space>
                    <a href="https://www.instagram.com/accounts/login/?next=/ditjenciptakarya/" target="_blank" rel="noreferrer">
                      <img src={ImgIg} alt="instagram" />
                    </a>
                    <a href="https://id-id.facebook.com/people/Direktorat-Jenderal-Cipta-Karya-PU/100066857310185/" target="_blank" rel="noreferrer">
                      <img src={ImgFb} alt="facebook" />
                    </a>
                    <a href="https://www.youtube.com/channel/UClw44wRLIIUq-2KXz9J_Xcg" target="_blank" rel="noreferrer">
                      <img src={ImgYt} alt="youtube" />
                    </a>
                    <a href="https://twitter.com/cipta_karya" target="_blank" rel="noreferrer">
                      <img src={ImgTwitter} alt="twitter" />
                    </a>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
