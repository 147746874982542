const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/organisasi"],
    exact: true,
    component: "Organisasi",
  },
  {
    path: ["/", "/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["/", "/glosarium"],
    exact: true,
    component: "Glosarium",
  },
  {
    path: ["/", "/sitemap"],
    exact: true,
    component: "Sitemap",
  },
  {
    path: ["/", "/logo"],
    exact: true,
    component: "Logo",
  },
  {
    path: ["/", "/berita"],
    exact: true,
    component: "Berita",
  },
  {
    path: ["/", "/berita-detail"],
    exact: true,
    component: "BeritaDetail",
  },
  {
    path: ["/", "/bppw"],
    exact: true,
    component: "Balai",
  },
  {
    path: ["/", "/bppw-detail"],
    exact: true,
    component: "BalaiDetail",
  },
  {
    path: ["/bppw", "/sumatera-utara"],
    exact: false,
    component: "BalaiDetail",
  },
  {
    path: ["/", "/search"],
    exact: true,
    component: "Search",
  },
  {
    path: ["/", "/kontak"],
    exact: true,
    component: "Kontak",
  },
  {
    path: ["/", "/produk"],
    exact: true,
    component: "Produk",
  },
  {
    path: ["/produk", "/laporan"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk", "/pedoman"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk", "/profil"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk-buletin"],
    exact: false,
    component: "Buletin",
  },
  {
    path: ["/", "/satupintu"],
    exact: true,
    component: "Satupintu",
  },
  {
    path: ["/", "/galeri"],
    exact: true,
    component: "Galeri",
  },
  {
    path: ["/", "/lokasi"],
    exact: true,
    component: "Kontak",
  }
];

export default routes;
